var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editSopStepForm" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm no-margin",
              attrs: {
                title: `[${_vm.sopStep.jobStepName}] 작업순서 상세정보`,
              },
            },
            [
              _c(
                "template",
                { slot: "card-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editable,
                            expression: "editable",
                          },
                        ],
                        attrs: {
                          isSubmit: _vm.isSaveSopStep,
                          url: _vm.saveSopStepUrl,
                          param: _vm.sopStep,
                          mappingType: _vm.mappingType,
                          label: "저장",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveSopStep,
                          btnCallback: _vm.saveSopStepCallback,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "card-detail" }, [
                _c("div", { staticClass: "col-5" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                      },
                      [
                        _c("c-upload-picture", {
                          attrs: {
                            height: "300px",
                            attachInfo: _vm.attachSopStepInfo,
                            editable: _vm.editable,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-xs-10 col-sm-10 col-md-10 col-lg-10",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            label: "작업순서",
                            name: "jobStepName",
                          },
                          model: {
                            value: _vm.sopStep.jobStepName,
                            callback: function ($$v) {
                              _vm.$set(_vm.sopStep, "jobStepName", $$v)
                            },
                            expression: "sopStep.jobStepName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-2" },
                      [
                        _c("c-text", {
                          attrs: {
                            required: "",
                            editable: _vm.editable,
                            type: "number",
                            label: "번호",
                            name: "jobStepNo",
                          },
                          model: {
                            value: _vm.sopStep.jobStepNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.sopStep, "jobStepNo", $$v)
                            },
                            expression: "sopStep.jobStepNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            rows: 2,
                            label: "설명",
                            name: "jobStepDesc",
                          },
                          model: {
                            value: _vm.sopStep.jobStepDesc,
                            callback: function ($$v) {
                              _vm.$set(_vm.sopStep, "jobStepDesc", $$v)
                            },
                            expression: "sopStep.jobStepDesc",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            label: "안전보호구",
                            name: "saftyShied",
                          },
                          model: {
                            value: _vm.sopStep.saftyShied,
                            callback: function ($$v) {
                              _vm.$set(_vm.sopStep, "saftyShied", $$v)
                            },
                            expression: "sopStep.saftyShied",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            label: "사용공구",
                            name: "useTool",
                          },
                          model: {
                            value: _vm.sopStep.useTool,
                            callback: function ($$v) {
                              _vm.$set(_vm.sopStep, "useTool", $$v)
                            },
                            expression: "sopStep.useTool",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            label: "주의사항",
                            name: "caution",
                          },
                          model: {
                            value: _vm.sopStep.caution,
                            callback: function ($$v) {
                              _vm.$set(_vm.sopStep, "caution", $$v)
                            },
                            expression: "sopStep.caution",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "col-7" },
                  [
                    _c(
                      "c-table",
                      {
                        ref: "tableHazard",
                        attrs: {
                          title: "유해위험요인 목록",
                          editable: _vm.editable,
                          columns: _vm.gridHazard.columns,
                          merge: _vm.gridHazard.merge,
                          data: _vm.sopStep.sopHazardFactors,
                          gridHeight: "650px",
                          selection: "multiple",
                          rowKey: "sopStepHazardId",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "customArea",
                            fn: function ({ props, col }) {
                              return [
                                col.name === "item"
                                  ? _c(
                                      "q-item",
                                      { staticClass: "card-sop-step-list" },
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c(
                                              "q-item-label",
                                              {
                                                staticClass:
                                                  "sopStep-card-title",
                                              },
                                              [
                                                props.row.editFlag !== "C"
                                                  ? _c("q-btn", {
                                                      staticClass:
                                                        "tableinnerBtn",
                                                      attrs: {
                                                        flat: "",
                                                        align: col.align,
                                                        color: "blue-6",
                                                        label: `${props.row["ramRiskHazardClassName"]} / ${props.row["riskHazardName"]}`,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.openHazardCasue(
                                                            props.row
                                                          )
                                                        },
                                                      },
                                                    })
                                                  : [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            `${props.row["ramRiskHazardClassName"]} / ${props.row["riskHazardName"]}`
                                                          ) +
                                                          " "
                                                      ),
                                                      _c(
                                                        "q-badge",
                                                        {
                                                          attrs: {
                                                            color: "red",
                                                          },
                                                        },
                                                        [_vm._v(" NEW ")]
                                                      ),
                                                    ],
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c(
                          "template",
                          { slot: "table-button" },
                          [
                            _c(
                              "q-btn-group",
                              { attrs: { outline: "" } },
                              [
                                _vm.editable
                                  ? _c("c-btn", {
                                      attrs: { label: "추가", icon: "add" },
                                      on: { btnClicked: _vm.addSopHazard },
                                    })
                                  : _vm._e(),
                                _vm.editable
                                  ? _c("c-btn", {
                                      attrs: { label: "제외", icon: "remove" },
                                      on: { btnClicked: _vm.deleteSopHazard },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }